<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="1"
      y1="4.625"
      x2="19"
      y2="4.625"
      stroke="currentColor"
      stroke-width="0.75"
    />
    <line
      x1="1"
      y1="9.625"
      x2="13"
      y2="9.625"
      stroke="currentColor"
      stroke-width="0.75"
    />
    <line
      x1="15"
      y1="9.625"
      x2="19"
      y2="9.625"
      stroke="currentColor"
      stroke-width="0.75"
    />
    <line
      x1="1"
      y1="14.625"
      x2="13"
      y2="14.625"
      stroke="currentColor"
      stroke-width="0.75"
    />
    <line
      x1="15"
      y1="14.625"
      x2="19"
      y2="14.625"
      stroke="currentColor"
      stroke-width="0.75"
    />
  </svg>
</template>